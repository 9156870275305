/* search */
.search {
	gap:0 30px;

	header {
		max-width:420px;

		h2,.h2,.descr {
			text-align: left;
		}
	}

	&-block {
		flex:1;
		max-width:700px;
	}

	&__item {
		align-items: start;
		gap:20px;

		&:not(:last-child) {
			margin-bottom: 50px;
		}

		img {
			width:36px;
			height:36px;
			margin:0;
		}

		.h5 {
			text-align: left;
			margin-bottom: 10px;
		}
	}

	&-text {
		flex:1;
	}

	@media screen and (max-width:992px) {
		header, &-block {
			max-width:100%;
			min-width:100%;
		}
	}

	@media screen and (max-width:768px) {
		header {
			h2, .h2 {
				text-align: center;
			}
		}
	}
}