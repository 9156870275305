/* started */
.started {
	gap:0 30px;

	header {
		max-width:540px;
		padding-top: 50px;

		.h2, h2, .descr {
			text-align: left;
		}
	}

	&-form {
		flex:1;
		max-width:560px;
		border-radius: 10px;
		background: #FFF;
		padding:50px 60px 60px;

		.check {
			margin-bottom: 38px;
		}
	}

	@media screen and (max-width:992px) {
		header, &-form {
			max-width:100%;
			min-width:100%;
		}
	}

	@media screen and (max-width:768px) {
		header {
			padding-top: 0;
			
			h2, .h2, .descr {
				text-align: center;
			}
		}

		&-form {
			padding:30px 30px 40px;
		}
	}
}