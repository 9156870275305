/* navigation mob */
.mobile-navigation {
	position: fixed;
	z-index: 101;
	width:0;
	left:0;
	bottom:0;
	top:0;
	background: #020A17;
	padding:20px 0;

	.close {
		display: none;
		position: absolute;
		@include sprite(24px,24px,"../img/sprite.svg#sprite--close");
		top:18px;
		right:-34px;
	}

	.btn, .menu {
		width:0;
		overflow: hidden;
	}

	.btn {
		white-space:nowrap;
		margin-bottom: 20px;
	}

	.menu {
		&-link {
			display: block;
			font-size: 17px;
			font-weight: 400;
			line-height: 130%;
			padding:20px 0;
		}
	}
	
	&.open {
		width:300px;
		padding:20px 30px;

		.close {
			display: block;
		}

		.btn, .menu {
			width:100%;
		}
	}
}