/* integrate */
.integrate {
	&-block {
		align-items: center;
		gap:20px;
	}

	&__item {
		img {
			margin-bottom:0;
		}
	}

	@media screen and (max-width:1000px) {
		&-block {
			justify-content: center;
		}
	}
}