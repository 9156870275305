/* contact */
.contact {
	overflow: hidden;
	background: #F3F6FF url("../img/decorate-dark.svg") no-repeat;
	background-position: bottom left;

	section {
		padding:0;
	}

	h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5 {
		text-align: left;
	}

	header {
		margin-bottom: 15px;
	}

	&-text {
		header {
			h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5 {
				text-align: left;
			}
		}
	}

	&-form {
		background: #fff;
		border-radius: 10px;
		padding:40px 60px;

		h1,h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5 {
			text-align: center;
			margin-bottom: 30px;
		}

		&_bottom {
			gap:10px 30px;
			align-items: center;

			.btn {
				width:100%;
				flex:1;
			}

			.descr {
				flex:1;
				font-size: 14px;
				font-weight: 400;
				line-height: 130%;
				color:#8D8F9A;
				text-align: left;

				a {
					font-size: inherit;
					font-weight: inherit;
					line-height: inherit;
				}
			}
		}
	}

	h1,h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5 {
		color:#111B2E;
	}

	h1,.h2 {
		margin-bottom: 15px;
	}

	@media screen and (min-width:993px) {
		padding-top: 80px;
		margin-top: -80px;

		&-block {
			grid-template-areas:
			"text form"
			"soc form";
			grid-template-rows: auto 1fr auto;
			grid-gap:50px 80px;
			align-items: start;
		}

		&-text {
			grid-area: text;
			max-width:419px;
		}

		&-form {
			grid-area: form;
		}

		&-soc {
			grid-area:soc;
		}
	}

	@media screen and (max-width:992px) {
		background: #F3F6FF;
		padding-top: 60px;
		padding-bottom: 50px;
		margin-top: -60px;

		&-text, &-form {
			margin-bottom: 35px;
		}
	}

	@media screen and (max-width:768px) {
		&-form {
			padding:30px 30px 20px;

			&_bottom {
				.descr {
					min-width:100%;
					text-align: center;
				}
			}
		}

		.soc-text {
			display: flex;
			flex-wrap:wrap;
			gap:18px 0;

			.soc__item {
				width:50%;
				min-width:50%;

				&:not(:last-child) {
					margin-bottom: 0;
				}
			}
		}
	}
}