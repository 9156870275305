html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, pre,
a, abbr, address, big, blockquote, cite, code,
del, dfn, em, img, ins, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li, form, label, input, fieldset, legend, caption,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, main, nav, output, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: none;
  outline: none;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

ol, ul, nav, menu {
  list-style: none;
}

img, video, canvas {
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: bottom;
}

a, button {
  cursor: pointer;
  margin: 0;
  padding: 0;
}

a:hover, button:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background: transparent;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th, td {
  vertical-align: middle;
}

iframe {
  width: 100%;
}

/* Figtree */
@font-face {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Figtree-Regular"), url("../fonts/Figtree-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Figtree";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Figtree-Italic"), url("../fonts/Figtree-Italic.woff2") format("woff2");
}
@font-face {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Figtree-SemiBold"), url("../fonts/Figtree-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "Figtree";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local("Figtree-SemiBoldItalic"), url("../fonts/Figtree-SemiBoldItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Figtree-Bold"), url("../fonts/Figtree-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Figtree";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Figtree-BoldItalic"), url("../fonts/Figtree-BoldItalic.woff2") format("woff2");
}
/* =========================== all */
body {
  font-family: "Figtree", sans-serif;
  color: #4D4F5B;
  background: #fff;
  line-height: 1.5;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "clig" off, "liga" off;
}

.body_scroll {
  overflow: hidden;
}

/* heading */
h1, .h1, h2, h3, h4, h5, .h2, .h3, .h4, .h5, h6, .h6 {
  font-weight: 700;
  color: #111B2E;
  text-align: center;
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  h1, .h1, h2, h3, h4, h5, .h2, .h3, .h4, .h5, h6, .h6 {
    margin-bottom: 20px;
  }
}

h1, .h1 {
  font-size: 54px;
  line-height: 125%;
  color: #fff;
  letter-spacing: -1.08px;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  h1, .h1 {
    font-size: 40px;
    line-height: 130%;
    letter-spacing: -0.4px;
  }
}

h2, .h2 {
  font-size: 42px;
  line-height: 130%;
  letter-spacing: -0.84px;
}
@media screen and (max-width: 768px) {
  h2, .h2 {
    font-size: 32px;
    letter-spacing: -0.32px;
  }
}

h3, .h3 {
  font-size: 34px;
  line-height: 130%;
  letter-spacing: -0.68px;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  h3, .h3 {
    font-size: 27px;
    letter-spacing: -0.27px;
    margin-bottom: 30px;
  }
}

h4, .h4 {
  font-size: 26px;
  line-height: 130%;
  letter-spacing: -0.26px;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  h4, .h4 {
    font-size: 24px;
    letter-spacing: -0.24px;
    margin-bottom: 25px;
  }
}

h5, .h5 {
  font-size: 22px;
  line-height: 130%;
  letter-spacing: -0.22px;
}
@media screen and (max-width: 768px) {
  h5, .h5 {
    font-size: 21px;
    letter-spacing: none;
  }
}

h6, .h6 {
  font-size: 18px;
  line-height: 150%;
}

.h_blue {
  color: #1C84FF;
}

section header {
  margin-bottom: 50px;
}
section header h1, section header .h1, section header h2, section header h3, section header h4, section header h5, section header h6, section header .h2, section header .h3, section header .h4, section header .h5, section header .h6 {
  max-width: 580px;
  margin: 0 auto;
}
section header h1 + .descr, section header .h1 + .descr, section header h2 + .descr, section header h3 + .descr, section header h4 + .descr, section header h5 + .descr, section header h6 + .descr, section header .h2 + .descr, section header .h3 + .descr, section header .h4 + .descr, section header .h5 + .descr, section header .h6 + .descr {
  margin-top: 15px;
}
@media screen and (max-width: 768px) {
  section header {
    margin-bottom: 35px;
  }
}

.descr {
  max-width: 734px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.descr p {
  color: #4D515B;
  font-size: 19px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .descr p {
    font-size: 17px;
    line-height: 150%;
    text-align: center;
  }
}

article {
  margin-bottom: 80px;
}
article section, article .section {
  padding: 0;
  margin-bottom: 40px;
}
article h1, article .h1, article h2, article h3, article h4, article h5, article h6, article .h2, article .h3, article .h4, article .h5, article .h6 {
  color: #111B2E;
  text-align: left;
  max-width: 100%;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  article h1, article .h1, article h2, article h3, article h4, article h5, article h6, article .h2, article .h3, article .h4, article .h5, article .h6 {
    margin-bottom: 30px;
  }
}
article header h1, article header .h1, article header h2, article header h3, article header h4, article header h5, article header h6, article header .h2, article header .h3, article header .h4, article header .h5, article header .h6 {
  text-align: left;
  max-width: 100%;
}
article p + h1, article p + .h1, article p + h2, article p + h3, article p + h4, article p + h5, article p + h6, article p + .h2, article p + .h3, article p + .h4, article p + .h5, article p + .h6 {
  margin-top: 40px;
}
article p + section {
  margin-top: 40px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
  color: #1C84FF;
  margin-bottom: 10px;
}

/* list */
ol:not([class]), ul:not([class]) {
  margin-bottom: 15px;
}

ol:not([class]) li, ul:not([class]) li {
  display: table;
  position: relative;
}
ol:not([class]) li:not(:last-child), ul:not([class]) li:not(:last-child) {
  margin-bottom: 10px;
}

table ol:not([class]) li, table ul:not([class]) li, .table ol:not([class]) li, .table ul:not([class]) li {
  display: block;
}

ol:not([class]) {
  counter-reset: number;
}

ol:not([class]) li::before {
  counter-increment: number;
  content: counter(number);
  color: #1C84FF;
  font-weight: 600;
}

ul:not([class]) li {
  padding-left: 25px;
}

ul:not([class]) li::before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: #1C84FF;
  top: 9px;
  left: 10px;
}

ol ol, ul ul {
  margin-left: 30px;
}

/* content */
a, p, ol:not([class]) li, ol li, ul:not([class]) li, ul li {
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
}

p {
  margin-bottom: 30px;
}

.text p:last-child {
  margin-bottom: 0;
}

a:not([class]) {
  color: #1C84FF;
}
a:not([class]):hover {
  text-decoration: underline;
}

b, strong {
  font-weight: 700;
}

i, em {
  font-style: italic;
}

/* img */
img {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

.float_right {
  float: right;
  margin-left: 20px;
}

.float_left {
  float: left;
  margin-right: 20px;
}

.float_right, .float_left {
  margin-bottom: 20px;
}

.float_right img, .float_left img {
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .float_right, .float_left {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}
/* transition */
* {
  transition: all 0.3s;
}

/* btn */
.btn {
  position: relative;
  z-index: 1;
  display: block;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 700;
  margin: 0 auto;
}

.btn_blue {
  width: 157px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #fff;
  background: #1C84FF;
  letter-spacing: -0.32px;
}
.btn_blue:hover {
  background: #0050C2;
}

.btn_white {
  width: 250px;
  height: 55px;
  line-height: 55px;
  font-size: 19px;
  letter-spacing: -0.38px;
  color: #111B2E;
  background: #fff;
}
.btn_white:hover {
  background: #0A58B4;
  color: #fff;
}

.overly {
  display: none;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
}

.clear::after {
  content: "";
  display: block;
  clear: both;
}

/* flexbox */
.flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* gridbox */
.gridbox {
  display: grid;
  justify-content: space-between;
}

/* breadcrumbs */
.breadcrumbs {
  justify-content: flex-start;
  align-content: center;
  margin: 30px 0;
}
.breadcrumbs li, .breadcrumbs a {
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
}
.breadcrumbs li {
  color: #8D8E9A;
}
.breadcrumbs li:not(:last-child) {
  margin-right: 22px;
}
.breadcrumbs a {
  position: relative;
  color: #111B2E;
  text-decoration: none;
}
.breadcrumbs a:hover {
  color: #8D8E9A;
  text-decoration: none;
}
.breadcrumbs a::after {
  position: absolute;
  content: "/";
  top: 0;
  right: -15px;
}
@media screen and (max-width: 768px) {
  .breadcrumbs {
    margin: 20px 0;
  }
}

/* soc */
.soc {
  gap: 15px;
}
.soc svg {
  min-width: 26;
  fill: #fff;
}
.soc-link:hover svg {
  fill: #B6C4EF;
}
.soc-text .soc__item:not(:last-child) {
  margin-bottom: 18px;
}
.soc-text .soc-link {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  color: #111B2E;
}
.soc-text .soc-link svg {
  fill: #111B2E;
}
.soc-text .soc-link:hover {
  color: #0A58B4;
}
.soc-text .soc-link:hover svg {
  fill: #0A58B4;
}

.share-heading {
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
  margin-bottom: 20px;
}

/* scroll vertical */
.scroll {
  overflow-y: auto;
  -ms-scroll-chaining: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
  scrollbar-color: #DCDFE9 transparent;
}

.scroll::-webkit-scrollbar {
  width: 4px;
  height: 50%;
}

.scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #DCDFE9;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color: #DCDFE9;
}

.scroll::-webkit-scrollbar-thumb:active {
  background-color: #DCDFE9;
}

.scroll::-webkit-scrollbar-thumb:vertical {
  min-height: 50%;
}

.scroll::-webkit-scrollbar-thumb:horizontal {
  min-width: 4px;
}

/* scroll horizontal */
.scroll_horizontal {
  overflow-x: auto;
  -ms-scroll-chaining: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
  scrollbar-color: #DCDFE9 transparent;
}

.scroll_horizontal::-webkit-scrollbar {
  width: 50%;
  height: 4px;
}

.scroll_horizontal::-webkit-scrollbar-track {
  background-color: transparent;
}

.scroll_horizontal::-webkit-scrollbar-thumb {
  background-color: #DCDFE9;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.scroll_horizontal::-webkit-scrollbar-thumb:hover {
  background-color: #DCDFE9;
}

.scroll_horizontal::-webkit-scrollbar-thumb:active {
  background-color: #DCDFE9;
}

.scroll_horizontal::-webkit-scrollbar-thumb:horizontal {
  min-width: 50%;
}

.scroll_horizontal::-webkit-scrollbar-thumb:vertical {
  min-height: 4px;
}

/* table */
@media screen and (max-width: 768px) {
  .table_scroll table {
    width: 700px;
  }
}

table {
  border: 1px solid #BFBFC0;
  border-collapse: separate;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}
table th, table td {
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #1D1B1B;
  padding: 18px 25px;
}
table th:not(:last-child), table td:not(:last-child) {
  border-right: 1px solid #D9D9D9;
}
table th {
  background: #EDF9FF;
  font-weight: 600;
}
table tr:nth-child(even) td {
  background: #F5F5F8;
}

/* form */
form input, form textarea {
  position: relative;
  z-index: 2;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  border-bottom: 1px solid #111B2E;
  width: 100%;
  font-family: "Figtree";
  font-size: 17px;
  font-weight: 400;
  color: #111B2E;
  background: transparent;
  padding-top: 15px;
}
form input {
  height: 56px;
}
form textarea {
  padding-top: 15px;
}
form .group {
  position: relative;
  margin-bottom: 22px;
}
form .icon, form .label {
  position: absolute;
  z-index: 1;
  display: none;
  width: 100%;
}
form .icon {
  right: 0;
  top: 22px;
}
form .icon-ok {
  width: 18px;
  height: 18px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--ok");
}
form .icon-false {
  width: 18px;
  height: 18px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--false");
}
form .label-descr {
  display: block;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  top: calc(50% - 12px);
  left: 0;
  color: #65667B;
}
form .btn {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  height: 55px;
  line-height: 55px;
}

button {
  font-family: "Figtree";
  cursor: pointer;
}

input::placeholder, textarea::placeholder {
  color: #65667B;
  opacity: 0;
  font-family: "Figtree";
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #65667B;
  opacity: 0;
  font-family: "Figtree";
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
}
input:-moz-placeholder, textarea:-moz-placeholder {
  color: #65667B;
  opacity: 0;
  font-family: "Figtree";
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
}
input::-moz-placeholder, textarea::-moz-placeholder {
  color: #65667B;
  opacity: 0;
  font-family: "Figtree";
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #65667B;
  opacity: 0;
  font-family: "Figtree";
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
}
input:not(:focus):placeholder-shown, textarea:not(:focus):placeholder-shown {
  border-color: #111B2E;
}
input:not(:focus):placeholder-shown ~ .icon, textarea:not(:focus):placeholder-shown ~ .icon {
  display: none;
}
input:not(:focus):placeholder-shown .label-descr, textarea:not(:focus):placeholder-shown .label-descr {
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  top: calc(50% - 12px);
  left: 0;
  color: #65667B;
}
input:focus, textarea:focus {
  outline: none;
}
input:focus ~ .icon, textarea:focus ~ .icon {
  display: none;
}
input:focus ~ .label-descr, textarea:focus ~ .label-descr {
  color: #4D4F5B;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  top: 2.5px;
}
input:focus:valid ~ .icon-ok, textarea:focus:valid ~ .icon-ok {
  display: none;
}
input:focus:valid ~ .label-descr, textarea:focus:valid ~ .label-descr {
  color: #4D4F5B;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  top: 2.5px;
}
input:valid ~ .icon-ok, textarea:valid ~ .icon-ok {
  display: block;
}
input:valid ~ .label-descr, textarea:valid ~ .label-descr {
  color: #4D4F5B;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  top: 2.5px;
}
input:invalid:not(:focus):not(:placeholder-shown), textarea:invalid:not(:focus):not(:placeholder-shown) {
  border-color: #FF002E;
}
input:invalid:not(:focus):not(:placeholder-shown) ~ .icon-false, textarea:invalid:not(:focus):not(:placeholder-shown) ~ .icon-false {
  display: block;
}
input:invalid:not(:focus):not(:placeholder-shown) ~ .label-descr, textarea:invalid:not(:focus):not(:placeholder-shown) ~ .label-descr {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  top: 2.5px;
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

/* checking */
.check {
  position: relative;
  display: flex;
  min-height: 28px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: #111B2E;
  text-align: left;
  padding-left: 40px;
}
.check-group {
  justify-content: flex-start;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .check {
    font-size: 14px;
  }
}

.check__input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.check__box {
  position: absolute;
  left: 0px;
  top: calc(50% - 14px);
  width: 28px;
  height: 28px;
  border-radius: 7px;
  border: 1px solid #CCCDDD;
}

.radio .check__box {
  border-radius: 100%;
}
.radio .check__input:checked + .check__box {
  border-color: #1C84FF;
  background: #1C84FF;
}
.radio .check__input:checked + .check__box::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #fff;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
}

.option .check__input:checked + .check__box {
  border-color: #1C84FF;
}
.option .check__input:checked + .check__box::before {
  position: absolute;
  content: "";
  width: 29px;
  height: 29px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--checkbox");
  top: 0;
  left: 0;
  margin-left: -1px;
  margin-top: -1px;
}

/* select listing */
.select-listing {
  position: relative;
  width: 368px;
  margin: 0 auto;
}
.select-listing-list {
  display: none;
  position: absolute;
  z-index: 4;
  width: 100%;
  left: 0;
  right: 0;
  top: 54px;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
  padding: 6px;
}
.select-listing-list__item {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #101828;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  padding: 12px 10px;
}
.select-listing-list__item:hover {
  background: #F3F3F7;
  color: #101828;
}
.select-listing .select-value {
  font-size: 17px;
  font-weight: 400;
}
.select-listing-wrapper {
  height: 144px;
}
.select-listing-all .select-btn {
  color: #101828;
  padding: 22px 16px 9px;
}
.select-listing-all .select-btn svg {
  min-width: 14px;
  margin-top: -8px;
}
.select-listing-all .select-listing-list {
  top: 62px;
}
.select-listing.open .select-btn svg {
  transform: rotate(-180deg);
}
.select-listing.open .select-listing-list {
  display: block;
}

.select-btn {
  position: relative;
  width: 100%;
  align-items: center;
  font-size: 17px;
  font-weight: 400;
  color: #656A7B;
  border-bottom: 1px solid #111B2E;
  height: 56px;
}
.select-btn svg {
  fill: #111B2E;
}
.select-btn.select-choise .select-value {
  color: #111B2E;
}

.select-title {
  position: absolute;
  top: 8px;
  left: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #4D515B;
}

.select-listing {
  width: 100%;
}
.select-listing-list {
  top: 59px;
}
.select-listing .select-btn {
  width: 100%;
  height: 56px;
  padding-top: 20px;
}

/* container */
main {
  padding-top: 80px;
}
@media screen and (max-width: 992px) {
  main {
    padding-top: 60px;
  }
}

.container {
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
}

.section {
  position: relative;
  padding: 80px 0;
}
.section_dark {
  background: #171717 url("../img/decorate.svg") no-repeat;
  background-position: bottom right;
  color: #fff;
}
.section_dark h2, .section_dark .h2, .section_dark .descr {
  color: #fff;
}
.section_white {
  background: #fff;
}
.section_gray {
  background: #F2F4F6;
}
.section_light {
  background: #F3F6FF url("../img/decorate-dark.svg") no-repeat;
  background-position: top left;
}
@media screen and (max-width: 768px) {
  .section {
    padding: 50px 0;
  }
  .section_dark {
    background: #171717;
  }
  .section_light {
    background: #F3F6FF;
  }
}

.logo img {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .logo img {
    width: 218px;
    height: 32px;
  }
}

/* header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
}
.header .container {
  height: 80px;
  align-items: center;
}
.header-navigation {
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}
.header-btn {
  width: 0;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
}
.header_fixed {
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
}
.header_fixed .menu-link {
  color: #111B2E;
}
.header_fixed .header-btn {
  width: 155px;
  margin-left: 40px;
}
.header_fixed .hamburger svg {
  fill: #111B2E;
}
.header:not(.header-main) .menu-link {
  color: #111B2E;
}
.header:not(.header-main) .menu-link:hover {
  color: #0A58B4;
}
.header:not(.header-main) .btn {
  width: 155px;
  margin-left: 40px;
}
.header:not(.header-main) .hamburger svg {
  fill: #111B2E;
}
@media screen and (max-width: 992px) {
  .header .container {
    height: 60px;
  }
  .header-btn {
    height: 36px;
    font-size: 15px;
    letter-spacing: -0.3px;
  }
  .header .btn_hidden {
    display: none;
  }
  .header_fixed .header-btn {
    width: 70px;
    line-height: 36px;
    margin-left: 0;
    margin-right: 20px;
  }
  .header:not(.header-main) .header-btn {
    width: 70px;
    line-height: 36px;
    font-size: 15px;
    letter-spacing: -0.3px;
    margin-right: 20px;
    margin-left: 5px;
  }
}
@media screen and (max-width: 768px) {
  .header .logo img {
    width: 206px;
    height: 27px;
  }
}

/* menu */
.menu {
  gap: 40px;
}
.menu-link {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #fff;
}
.menu-link:hover {
  color: #0A58B4;
}

/* hamburger */
.hamburger {
  display: none;
  width: 22px;
  min-width: 22px;
  height: 18px;
}
.hamburger svg {
  fill: #fff;
}

@media screen and (max-width: 992px) {
  .header-navigation .menu {
    display: none;
  }
  .hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* navigation mob */
.mobile-navigation {
  position: fixed;
  z-index: 101;
  width: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background: #020A17;
  padding: 20px 0;
}
.mobile-navigation .close {
  display: none;
  position: absolute;
  width: 24px;
  height: 24px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--close");
  top: 18px;
  right: -34px;
}
.mobile-navigation .btn, .mobile-navigation .menu {
  width: 0;
  overflow: hidden;
}
.mobile-navigation .btn {
  white-space: nowrap;
  margin-bottom: 20px;
}
.mobile-navigation .menu-link {
  display: block;
  font-size: 17px;
  font-weight: 400;
  line-height: 130%;
  padding: 20px 0;
}
.mobile-navigation.open {
  width: 300px;
  padding: 20px 30px;
}
.mobile-navigation.open .close {
  display: block;
}
.mobile-navigation.open .btn, .mobile-navigation.open .menu {
  width: 100%;
}

/* promo */
.promo {
  position: relative;
  text-align: center;
  color: #fff;
  padding: 165px 0 100px;
  margin-top: -80px;
}
.promo::before, .promo::after {
  position: absolute;
  content: "";
}
.promo::after {
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}
.promo::before {
  width: 468px;
  height: 468px;
  z-index: 0;
  background: url("../img/decorate.svg") no-repeat;
  background-position: bottom left;
  left: 0;
  bottom: 0;
}
.promo-bg {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}
.promo header {
  margin-bottom: 40px;
}
.promo header h1 {
  max-width: 550px;
  margin: 0 auto 15px;
}
.promo .descr {
  max-width: 850px;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  margin: 0 auto;
}
@media screen and (max-width: 992px) {
  .promo {
    margin-top: -60px;
    padding: 120px 0 100px;
  }
  .promo::before {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .promo .time {
    font-size: 16px;
    line-height: 125%;
    letter-spacing: -0.32px;
    margin-bottom: 20px;
  }
  .promo .descr {
    font-size: 16px;
    margin-bottom: 40px;
  }
}

/* whats */
.whats__item {
  text-align: left;
  flex: 1;
}
.whats__item:first-child {
  padding-right: 60px;
}
.whats__item:nth-child(2) {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 60px;
}
.whats__item:last-child {
  padding-left: 60px;
}
.whats-number {
  color: #656A7B;
  font-size: 42px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.84px;
  margin-bottom: 10px;
}
.whats .h5 {
  text-align: left;
  margin-bottom: 10px;
}
@media screen and (max-width: 992px) {
  .whats__item:first-child {
    padding-right: 30px;
  }
  .whats__item:nth-child(2) {
    padding: 0 30px;
  }
  .whats__item:last-child {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .whats__item {
    min-width: 100%;
    text-align: center;
    padding: 30px 0;
  }
  .whats__item:first-child {
    padding-right: 0;
    padding-top: 0;
  }
  .whats__item:nth-child(2) {
    border: none;
    padding: 30px 0;
  }
  .whats__item:last-child {
    padding-left: 0;
    padding-bottom: 0;
  }
  .whats__item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .whats-number {
    font-size: 40px;
    letter-spacing: -0.4px;
  }
  .whats .h5 {
    text-align: center;
  }
}

/* power */
.power-block {
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 40px;
}
.power__item {
  border-radius: 10px;
  background: #FFF;
  padding: 50px;
}
.power__item .text {
  max-width: 410px;
}
.power__item .text p {
  color: #111B2E;
}
.power__item .h3 {
  text-align: left;
  margin-bottom: 15px;
}
.power_blue {
  color: #fff;
  background: linear-gradient(76deg, #0957B2 0.7%, #1C84FF 100%);
}
.power_blue .h3 {
  color: #fff;
}
.power_blue .text p {
  color: #fff;
}
@media screen and (max-width: 768px) {
  .power-block {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 20px;
  }
  .power__item {
    padding: 30px;
  }
  .power__item .h3 {
    margin-bottom: 10px;
  }
}

/* integrate */
.integrate-block {
  align-items: center;
  gap: 20px;
}
.integrate__item img {
  margin-bottom: 0;
}
@media screen and (max-width: 1000px) {
  .integrate-block {
    justify-content: center;
  }
}

/* features */
.features-block {
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 50px;
}
.features__item {
  color: #fff;
}
.features__item img {
  width: 48px;
  height: 48px;
  margin: 0 0 15px;
}
.features__item .h4 {
  color: #fff;
  text-align: left;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .features-block {
    display: block;
  }
  .features__item {
    position: relative;
    min-width: 100%;
    padding-left: 56px;
  }
  .features__item:not(:last-child) {
    margin-bottom: 35px;
  }
  .features__item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
  }
}

/* search */
.search {
  gap: 0 30px;
}
.search header {
  max-width: 420px;
}
.search header h2, .search header .h2, .search header .descr {
  text-align: left;
}
.search-block {
  flex: 1;
  max-width: 700px;
}
.search__item {
  align-items: start;
  gap: 20px;
}
.search__item:not(:last-child) {
  margin-bottom: 50px;
}
.search__item img {
  width: 36px;
  height: 36px;
  margin: 0;
}
.search__item .h5 {
  text-align: left;
  margin-bottom: 10px;
}
.search-text {
  flex: 1;
}
@media screen and (max-width: 992px) {
  .search header, .search-block {
    max-width: 100%;
    min-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .search header h2, .search header .h2 {
    text-align: center;
  }
}

/* started */
.started {
  gap: 0 30px;
}
.started header {
  max-width: 540px;
  padding-top: 50px;
}
.started header .h2, .started header h2, .started header .descr {
  text-align: left;
}
.started-form {
  flex: 1;
  max-width: 560px;
  border-radius: 10px;
  background: #FFF;
  padding: 50px 60px 60px;
}
.started-form .check {
  margin-bottom: 38px;
}
@media screen and (max-width: 992px) {
  .started header, .started-form {
    max-width: 100%;
    min-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .started header {
    padding-top: 0;
  }
  .started header h2, .started header .h2, .started header .descr {
    text-align: center;
  }
  .started-form {
    padding: 30px 30px 40px;
  }
}

/* faq */
.faq {
  gap: 0 30px;
}
.faq header {
  max-width: 370px;
}
.faq header .title, .faq header h2, .faq header .h2 {
  text-align: left;
}
.faq-block {
  max-width: 730px;
  flex: 1;
}
.faq__item {
  overflow: hidden;
  border-radius: 10px;
  background: #F2F4F6;
  padding: 20px 30px;
}
.faq__item:not(:last-child) {
  margin-bottom: 20px;
}
.faq__item .heading {
  align-items: center;
  position: relative;
  flex-wrap: nowrap;
  font-size: 17px;
  font-weight: 600;
  line-height: 150%;
  list-style: none;
  color: #111B2E;
  cursor: pointer;
  gap: 20px;
}
.faq__item .heading svg {
  min-width: 12px;
  fill: #111B2E;
}
.faq__item .heading::marker {
  display: none;
}
.faq__item .heading:hover {
  color: #0A58B4;
}
.faq__item.open .heading {
  color: #0A58B4;
}
.faq__item.open .heading svg {
  fill: #0A58B4;
  transform: rotate(-180deg);
}
.faq .text p:first-child {
  padding-top: 10px;
}
@media screen and (max-width: 992px) {
  .faq header, .faq-block {
    max-width: 100%;
    min-width: 100%;
  }
  .faq header .title, .faq header h2, .faq header .h2, .faq-block .title, .faq-block h2, .faq-block .h2 {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .faq__item {
    padding: 16px 20px;
  }
}

/* contact */
.contact {
  overflow: hidden;
  background: #F3F6FF url("../img/decorate-dark.svg") no-repeat;
  background-position: bottom left;
}
.contact section {
  padding: 0;
}
.contact h2, .contact h3, .contact h4, .contact h5, .contact .h1, .contact .h2, .contact .h3, .contact .h4, .contact .h5 {
  text-align: left;
}
.contact header {
  margin-bottom: 15px;
}
.contact-text header h2, .contact-text header h3, .contact-text header h4, .contact-text header h5, .contact-text header .h1, .contact-text header .h2, .contact-text header .h3, .contact-text header .h4, .contact-text header .h5 {
  text-align: left;
}
.contact-form {
  background: #fff;
  border-radius: 10px;
  padding: 40px 60px;
}
.contact-form h1, .contact-form h2, .contact-form h3, .contact-form h4, .contact-form h5, .contact-form .h1, .contact-form .h2, .contact-form .h3, .contact-form .h4, .contact-form .h5 {
  text-align: center;
  margin-bottom: 30px;
}
.contact-form_bottom {
  gap: 10px 30px;
  align-items: center;
}
.contact-form_bottom .btn {
  width: 100%;
  flex: 1;
}
.contact-form_bottom .descr {
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  color: #8D8F9A;
  text-align: left;
}
.contact-form_bottom .descr a {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.contact h1, .contact h2, .contact h3, .contact h4, .contact h5, .contact .h1, .contact .h2, .contact .h3, .contact .h4, .contact .h5 {
  color: #111B2E;
}
.contact h1, .contact .h2 {
  margin-bottom: 15px;
}
@media screen and (min-width: 993px) {
  .contact {
    padding-top: 80px;
    margin-top: -80px;
  }
  .contact-block {
    grid-template-areas: "text form" "soc form";
    grid-template-rows: auto 1fr auto;
    grid-gap: 50px 80px;
    align-items: start;
  }
  .contact-text {
    grid-area: text;
    max-width: 419px;
  }
  .contact-form {
    grid-area: form;
  }
  .contact-soc {
    grid-area: soc;
  }
}
@media screen and (max-width: 992px) {
  .contact {
    background: #F3F6FF;
    padding-top: 60px;
    padding-bottom: 50px;
    margin-top: -60px;
  }
  .contact-text, .contact-form {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 768px) {
  .contact-form {
    padding: 30px 30px 20px;
  }
  .contact-form_bottom .descr {
    min-width: 100%;
    text-align: center;
  }
  .contact .soc-text {
    display: flex;
    flex-wrap: wrap;
    gap: 18px 0;
  }
  .contact .soc-text .soc__item {
    width: 50%;
    min-width: 50%;
  }
  .contact .soc-text .soc__item:not(:last-child) {
    margin-bottom: 0;
  }
}

/* footer */
.footer {
  background: #020A17;
  padding: 80px 0;
}
.footer_top {
  gap: 40px 30px;
  align-items: center;
  padding-bottom: 30px;
}
.footer_bottom {
  text-align: center;
  padding-top: 30px;
}
.footer-info {
  flex: 1;
  max-width: 400px;
}
.footer .logo {
  display: inline-block;
  margin-bottom: 30px;
}
.footer-nav {
  flex: 1;
  gap: 40px 30px;
}
.footer-heading {
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
  color: #8D8F9A;
  margin-bottom: 20px;
}
.footer__item:not(:last-child) {
  margin-bottom: 15px;
}
.footer-link {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  line-height: 150%;
}
.footer-link:hover {
  color: #B6C4EF;
}
.footer .copy {
  font-size: 17px;
  font-weight: 400;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.6);
}
.footer .soc {
  justify-content: flex-start;
}
@media screen and (max-width: 992px) {
  .footer_top {
    justify-content: center;
  }
  .footer-info, .footer-nav {
    min-width: 100%;
    max-width: 100%;
  }
  .footer-info {
    text-align: center;
  }
  .footer-info .soc {
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 50px 0;
  }
}