/* promo */
.promo {
	position: relative;
	text-align: center;
	color:#fff;
	padding:165px 0 100px;
	margin-top: -80px;

	&::before, &::after {
		position: absolute;
		content:"";
	}

	&::after {
		z-index: -1;
		width:100%;
		height:100%;
		object-fit:cover;
		top:0;
		left:0;
		background: rgba(0, 0, 0, .8);
	}

	&::before {
		width:468px;
		height:468px;
		z-index: 0;
		background:url("../img/decorate.svg") no-repeat;
		background-position: bottom left;
		left:0;
		bottom:0;
	}

	&-bg {
		position: absolute;
		z-index: -2;
		top:0;
		left:0;
		width:100%;
		height:100%;
		object-fit:cover;
		object-position:center top;
	}

	header {
		margin-bottom: 40px;

		h1 {
			max-width:550px;
			margin:0 auto 15px;
		}
	}

	.descr {
		max-width:850px;
		font-size: 20px;
		font-weight: 400;
		line-height: 150%;
		margin:0 auto;
	}

	@media screen and (max-width:992px) {
		margin-top: -60px;
		padding:120px 0 100px;

		&::before {
			display: none;
		}
	}

	@media screen and (max-width:768px) {
		.time {
			font-size: 16px;
			line-height: 125%;
			letter-spacing: -0.32px; 
			margin-bottom: 20px;
		}

		.descr {
			font-size: 16px;
			margin-bottom: 40px;
		}
	}
}