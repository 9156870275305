/* whats */
.whats {
	&__item {
		text-align: left;
		flex:1;

		&:first-child {
			padding-right: 60px;
		}

		&:nth-child(2) {
			border-left:1px solid rgba(0, 0, 0, 0.10);
			border-right:1px solid rgba(0, 0, 0, 0.10);
			padding:0 60px;
		}

		&:last-child {
			padding-left: 60px;
		}
	}

	&-number {
		color:#656A7B;
		font-size: 42px;
		font-weight: 700;
		line-height: 130%;
		letter-spacing: -0.84px; 
		margin-bottom: 10px;
	}

	.h5 {
		text-align: left;
		margin-bottom: 10px;
	}

	@media screen and (max-width:992px) {
		&__item {
			&:first-child {
				padding-right: 30px;
			}

			&:nth-child(2) {
				padding:0 30px;
			}

			&:last-child {
				padding-left: 30px;
			}
		}
	}

	@media screen and (max-width:768px) {
		&__item {
			min-width:100%;
			text-align: center;
			padding:30px 0;

			&:first-child {
				padding-right: 0;
				padding-top: 0;
			}

			&:nth-child(2) {
				border:none;
				padding:30px 0;
			}

			&:last-child {
				padding-left: 0;
				padding-bottom: 0;
			}

			&:not(:last-child) {
				border-bottom:1px solid rgba(0, 0, 0, 0.10);
			}
		}

		&-number {
			font-size: 40px;
			letter-spacing: -0.4px;
		}

		.h5 {
			text-align: center;
		}
	}
}