/* faq */
.faq {
	gap:0 30px;
	
	header {
		max-width:370px;

		.title, h2, .h2 {
			text-align: left;
		}
	}

	&-block {
		max-width:730px;
		flex:1;
	}

	&__item {
		overflow: hidden;
		border-radius: 10px;
		background: #F2F4F6;
		padding:20px 30px;

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		.heading {
			align-items: center;
			position: relative;
			flex-wrap:nowrap;
			font-size: 17px;
			font-weight: 600;
			line-height: 150%;
			list-style: none;
			color:#111B2E;
			cursor: pointer;
			gap:20px;

			svg {
				min-width:12px;
				fill:#111B2E;
			}

			&::marker {
				display: none;
			}

			&:hover {
				color:#0A58B4;
			}
		}

		&.open {
			.heading {
				color:#0A58B4;

				svg {
					fill:#0A58B4;
					transform:rotate(-180deg);
				}
			}
		}
	}

	.text {
		p {
			&:first-child {
				padding-top: 10px;
			}
		}
	}

	@media screen and (max-width:992px) {
		header, &-block {
			max-width:100%;
			min-width:100%;

			.title, h2, .h2 {
				text-align: center;
			}
		}
	}

	@media screen and (max-width:768px) {
		&__item {
			padding:16px 20px;
		}
	}
}