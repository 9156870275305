/* Figtree */
@font-face {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Figtree-Regular"),
  url("../fonts/Figtree-Regular.woff2") format("woff2"); }

  @font-face {
  font-family: "Figtree";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Figtree-Italic"),
  url("../fonts/Figtree-Italic.woff2") format("woff2");}

  @font-face {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Figtree-SemiBold"),
  url("../fonts/Figtree-SemiBold.woff2") format("woff2"); }

  @font-face {
  font-family: "Figtree";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local("Figtree-SemiBoldItalic"),
  url("../fonts/Figtree-SemiBoldItalic.woff2") format("woff2");}

@font-face {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Figtree-Bold"),
  url("../fonts/Figtree-Bold.woff2") format("woff2"); }

   @font-face {
  font-family: "Figtree";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Figtree-BoldItalic"),
  url("../fonts/Figtree-BoldItalic.woff2") format("woff2");}