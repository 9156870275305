/* header */
.header {
	position: fixed;
	top:0;
	left:0;
	right:0;
	width:100%;
	z-index: 99;

	.container {
		height:80px;
		align-items: center;
	}

	&-navigation {
		flex:1;
		align-items: center;
		justify-content: flex-end;
		margin-left: auto;
	}

	&-btn {
		width:0;
		white-space:nowrap;
		overflow: hidden;
		margin:0;
	}

	&_fixed {
		background: #FFF;
		box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);

		.menu {
			&-link {
				color:#111B2E;
			}
		}

		.header-btn {
			width:155px;
			margin-left: 40px;
		}

		.hamburger {
			svg {
				fill:#111B2E;
			}
		}
	}

	&:not(.header-main) {
		.menu {
			&-link {
				color:#111B2E;

				&:hover {
					color:#0A58B4;
				}
			}
		}

		.btn {
			width:155px;
			margin-left: 40px;
		}

		.hamburger {
			svg {
				fill:#111B2E;
			}
		}
	}

	@media screen and (max-width:992px) {
		.container {
			height:60px;
		}

		&-btn {
			height:36px;
			font-size: 15px;
			letter-spacing: -0.3px; 
		}

		.btn_hidden {
			display: none;
		}

		&_fixed {
			.header-btn {
				width:70px;
				line-height: 36px;
				margin-left: 0;
				margin-right: 20px;
			}
		}

		&:not(.header-main) {
			.header-btn {
				width:70px;
				line-height: 36px;
				font-size: 15px;
				letter-spacing: -0.3px; 
				margin-right: 20px;
				margin-left: 5px;
			}
		}
	}

	@media screen and (max-width:768px) {
		.logo {
			img {
				width:206px;
				height:27px;
			}
		}
	}
}

/* menu */
.menu {
	gap:40px;

	&-link {
		font-size: 16px;
		font-weight: 600;
		line-height: 22px;
		color:#fff;

		&:hover {
			color:#0A58B4;
		}
	}
}

/* hamburger */
.hamburger {
	display: none;
	width:22px;
	min-width:22px;
	height:18px;

	svg {
		fill:#fff;
	}
}

@media screen and (max-width: 992px) {
	.header-navigation {
		.menu {
			display: none;
		}
	}

	.hamburger {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}