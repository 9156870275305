/* features */
.features {
	&-block {
		grid-template-columns:repeat(auto-fit,minmax(280px, 1fr));
		grid-gap:50px;
	}

	&__item {
		color:#fff;

		img {
			width:48px;
			height:48px;
			margin:0 0 15px;
		}

		.h4 {
			color:#fff;
			text-align: left;
			margin-bottom: 10px;
		}
	}

	@media screen and (max-width:768px) {
		&-block {
			display: block;
		}

		&__item {
			position: relative;
			min-width:100%;
			padding-left: 56px;

			&:not(:last-child) {
				margin-bottom: 35px;
			}

			img {
				position: absolute;
				top:0;
				left:0;
				width:36px;
				height:36px;
			}
		}
	}
}