/* =========================== all */
body {
	font-family: "Figtree", sans-serif;
	color: #4D4F5B;
	background: #fff;
	line-height: 1.5;
	font-variant-numeric: lining-nums proportional-nums;
	font-feature-settings: 'clig' off, 'liga' off; 
}

.body_scroll {
	overflow: hidden;
}


/* heading */
h1,.h1,h2,h3,h4,h5, .h2, .h3, .h4, .h5, h6,.h6 {
	font-weight: 700;
	color: #111B2E;
	text-align: center;
	margin-bottom: 25px;

	@media screen and (max-width:768px) {
		margin-bottom: 20px;
	}
}

h1, .h1 {
	font-size: 54px;
	line-height: 125%;
	color: #fff;
	letter-spacing: -1.08px; 
	margin-bottom: 15px;

	@media screen and (max-width: 768px) {
		font-size: 40px;
		line-height: 130%;
		letter-spacing: -0.4px;
	}
}

h2, .h2 {
	font-size: 42px;
	line-height: 130%;
	letter-spacing: -0.84px; 

	@media screen and (max-width: 768px) {
		font-size: 32px;
		letter-spacing: -0.32px; 
	}
}

h3, .h3 {
	font-size: 34px;
	line-height: 130%;
	letter-spacing: -0.68px; 
	margin-bottom: 40px;

	@media screen and (max-width: 768px) {
		font-size: 27px;
		letter-spacing: -0.27px; 
		margin-bottom: 30px;
	}
}

h4, .h4 {
	font-size: 26px;
	line-height: 130%;
	letter-spacing: -0.26px; 
	margin-bottom: 20px;

	@media screen and (max-width: 768px) {
		font-size: 24px;
		letter-spacing: -0.24px;
		margin-bottom: 25px;
	}
}

h5, .h5 {
	font-size: 22px;
	line-height: 130%;
	letter-spacing: -0.22px; 

	@media screen and (max-width: 768px) {
		font-size: 21px;
		letter-spacing: none;
	}
}

h6,.h6 {
	font-size: 18px;
	line-height: 150%;
}

.h_blue {
	color:#1C84FF;
}

section {
	header {
		margin-bottom: 50px;

		h1, .h1, h2 ,h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
			max-width:580px;
			margin:0 auto;

			+ .descr {
				margin-top: 15px;
			}
		}
	}
	@media screen and (max-width:768px) {
		header {
			margin-bottom: 35px;
		}
	}
}

.descr {
	max-width:734px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;

	p {
		color:#4D515B;
		font-size: 19px;
		font-weight: 400;
		line-height: 150%;
		margin-bottom: 0;
	}

	@media screen and (max-width:768px) {
		p {
			font-size: 17px;
			line-height: 150%;
			text-align: center;
		}
	}
}

article {
	margin-bottom: 80px;

	section, .section {
		padding:0;
		margin-bottom: 40px;
	}

	h1, .h1, h2 ,h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
		color:#111B2E;
		text-align: left;
		max-width:100%;
		margin-bottom: 40px;

		@media screen and (max-width:768px) {
			margin-bottom: 30px;
		}
	}

	header {
		h1, .h1, h2 ,h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
			text-align: left;
			max-width:100%;
		}
	}

	p + h1, p + .h1, p + h2 , p + h3, p + h4, p + h5, p + h6, p + .h2, p + .h3, p + .h4, p + .h5, p + .h6 {
		margin-top: 40px;
	}

	p + section {
		margin-top: 40px;
	}
}

.title {
	font-size: 18px;
	font-weight: 700;
	line-height: 150%;
	text-transform: uppercase;
	color:#1C84FF;
	margin-bottom: 10px;
}

/* list */
ol:not([class]), ul:not([class]) {
	margin-bottom: 15px;
}

ol:not([class]) li, ul:not([class]) li {
	display:table;
	position: relative;

	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

table, .table {
	ol:not([class]) li, ul:not([class]) li {
		display: block;
	}
}

ol:not([class]) {
	counter-reset: number;
}

ol:not([class]) li::before {
	counter-increment: number;
	content: counter(number); 
	color: #1C84FF;
	font-weight: 600;
}

ul:not([class]) li {
	padding-left:25px;
}

ul:not([class]) li::before{
	position: absolute;
	content: "";
	width:7px;
	height:7px;
	border-radius:100%;
	background: #1C84FF;
	top:9px;
	left:10px;
}

ol ol, ul ul {
	margin-left:30px;
}

/* content */
a, p, ol:not([class]) li, ol li, ul:not([class]) li, ul li {
	font-weight: 400;
	font-size: 17px;
	line-height: 150%;
}

p {
	margin-bottom:30px;
}

.text {
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

a:not([class]) {
	color: #1C84FF;

	&:hover {
		text-decoration: underline;
	}
}

b, strong {
	font-weight:700;
}

i, em {
	font-style:italic;
}

/* img */
img {
	display:block;
	margin:0 auto;
	margin-bottom:20px;
}

.float_right {
	float:right;
	margin-left:20px;
}

.float_left {
	float:left;
	margin-right:20px;
}

.float_right, .float_left {
	margin-bottom: 20px;
}

.float_right img, .float_left img {
	margin-bottom:0px;
}

@media screen and (max-width:768px){
	.float_right, .float_left {
		float:none;
		margin-left:auto;
		margin-right:auto;
	}
}

/* transition */
* {
	transition:all .3s;
}

/* btn */
.btn {
	position: relative;
	z-index:1;
	display: block;
	cursor:pointer;
	text-align: center;
	text-decoration: none;
	border-radius:6px;
	font-weight: 700;
	margin:0 auto;
}

.btn_blue {
	width:157px;
	height:40px;
	line-height: 40px;
	font-size: 16px;
	color:#fff;
	background: #1C84FF;
	letter-spacing: -0.32px;

	&:hover {
		background: #0050C2;
	}
}

.btn_white {
	width:250px;
	height:55px;
	line-height: 55px;
	font-size: 19px;
	letter-spacing: -0.38px;
	color:#111B2E;
	background: #fff;

	&:hover {
		background: #0A58B4;
		color:#fff;
	}
}

.overly {
	display:none;
	position: fixed;
	z-index:100;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:rgba(#000,.8);
}

.clear {
	&::after {
		content: "";
		display: block; 
		clear: both;
	}
}

/* flexbox */
.flexbox {
	display: flex;
	flex-wrap:wrap;
	justify-content: space-between;
}

/* gridbox */
.gridbox {
	display: grid;
	justify-content: space-between;
}

/* breadcrumbs */
.breadcrumbs {
	justify-content: flex-start;
	align-content: center;
	margin: 30px 0;

	li, a {
		font-weight: 400;
		font-size: 15px;
		line-height: 150%;
	}

	li {
		color:#8D8E9A;

		&:not(:last-child) {
			margin-right:22px;
		}
	}

	a {
		position: relative;
		color:#111B2E;
		text-decoration: none;

		&:hover {
			color:#8D8E9A;
			text-decoration: none;
		}

		&::after {
			position: absolute;
			content: "/";
			top:0;
			right: -15px;
		}
	}

	@media screen and (max-width:768px) {
		margin:20px 0;
	}
}

/* soc */
.soc {
	gap:15px;

	svg {
		min-width:26;
		fill:#fff;
	}

	&-link {
		&:hover {
			svg {
				fill:#B6C4EF;
			}
		}
	}

	&-text {
		.soc__item {
			&:not(:last-child) {
				margin-bottom: 18px;
			}
		}

		.soc-link {
			display: flex;
			align-items: center;
			gap:10px;
			font-size: 16px;
			font-weight: 400;
			line-height: 160%;
			color:#111B2E;

			svg {
				fill:#111B2E;
			}

			&:hover {
				color:#0A58B4;

				svg {
					fill:#0A58B4;
				}
			}
		}
	}
}

.share {
	&-heading {
		font-size: 14px;
		font-weight: 700;
		line-height: 150%;
		text-transform: uppercase;
		margin-bottom: 20px;
	}
}

/* scroll vertical */
.scroll {
	overflow-y: auto;
	-ms-scroll-chaining: none;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	scrollbar-width: thin;
	scrollbar-color: #DCDFE9 transparent;
}

.scroll::-webkit-scrollbar {
	width: 4px;
	height: 50%;
}

.scroll::-webkit-scrollbar-track {
	background-color: transparent; 
}

.scroll::-webkit-scrollbar-thumb {
	background-color: #DCDFE9;
	-webkit-border-radius: 5px;
	border-radius: 5px; 
}

.scroll::-webkit-scrollbar-thumb:hover {
	background-color: #DCDFE9; 
}

.scroll::-webkit-scrollbar-thumb:active {
	background-color: #DCDFE9; 
}

.scroll::-webkit-scrollbar-thumb:vertical {
	min-height: 50%; 
}

.scroll::-webkit-scrollbar-thumb:horizontal {
	min-width: 4px; 
}

/* scroll horizontal */
.scroll_horizontal {
	overflow-x: auto;
	-ms-scroll-chaining: none;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	scrollbar-width: thin;
	scrollbar-color: #DCDFE9 transparent;
}

.scroll_horizontal::-webkit-scrollbar {
	width: 50%;
	height: 4px; 
}

.scroll_horizontal::-webkit-scrollbar-track {
	background-color: transparent; 
}

.scroll_horizontal::-webkit-scrollbar-thumb {
	background-color: #DCDFE9;
	-webkit-border-radius: 5px;
	border-radius: 5px; 
}

.scroll_horizontal::-webkit-scrollbar-thumb:hover {
	background-color: #DCDFE9; 
}

.scroll_horizontal::-webkit-scrollbar-thumb:active {
	background-color: #DCDFE9; 
}

.scroll_horizontal::-webkit-scrollbar-thumb:horizontal {
	min-width: 50%; 
}

.scroll_horizontal::-webkit-scrollbar-thumb:vertical {
	min-height: 4px; 
}

/* table */
.table_scroll {
	@media screen and (max-width:768px) {
		table {
			width:700px;
		}
	}
}

table {
	border: 1px solid #BFBFC0;
	border-collapse: separate;
	background: #fff;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 20px;

	th, td {
		vertical-align: middle;
		text-align: center;
		font-size: 16px;
		line-height: 24px;
		color: #1D1B1B;
		padding:18px 25px;

		&:not(:last-child) {
			border-right:1px solid #D9D9D9;
		}
	}

	th {
		background: #EDF9FF;
		font-weight: 600;
	}

	tr {
		&:nth-child(even) {
			td {
				background: #F5F5F8;
			}
		}
	}
}

/* form */
form {
	input, textarea {
		position: relative;
		z-index: 2;
		border:none;
		-webkit-appearance:none;
		appearance:none;
		border-bottom: 1px solid #111B2E;
		width:100%;
		font-family: "Figtree";
		font-size:17px;
		font-weight: 400;
		color: #111B2E;
		background: transparent;
		padding-top: 15px;
	}

	input {
		height: 56px;
	}

	textarea {
		padding-top: 15px;
	}

	.group {
		position: relative;
		margin-bottom: 22px;
	}

	.icon, .label {
		position: absolute;
		z-index: 1;
		display: none;
		width:100%;
	}

	.icon {
		right:0;
		top:22px;
	}

	.icon-ok {
		@include sprite(18px, 18px, "../img/sprite.svg#sprite--ok");
	}

	.icon-false {
		@include sprite(18px, 18px, "../img/sprite.svg#sprite--false");
	}

	.label-descr {
		display: block;
		font-weight: 400;
		font-size: 17px;
		line-height: 24px;
		top:calc(50% - 12px);
		left:0;
		color: #65667B;
	}

	.btn {
		width:100%;
		font-size: 20px;
		font-weight: 700;
		height:55px;
		line-height: 55px;
	}
}

button {
	font-family: "Figtree";
	cursor: pointer;
}

input, textarea {
	@include placeholder {
		color: #65667B;
		opacity: 0;
		font-family: "Figtree";
		font-size:17px;
		font-weight: 400;
		line-height: 24px;
	}

	&:not(:focus):placeholder-shown {
		border-color:#111B2E;

		~ .icon {
			display: none;
		}

		.label-descr {
			font-weight: 400;
			font-size: 17px;
			line-height: 24px;
			top:calc(50% - 12px);
			left:0;
			color: #65667B;
		}	
	}

	&:focus {
		outline:none;

		~ .icon {
			display: none;
		}

		~ .label-descr {
			color:#4D4F5B;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			top:2.5px;
		}
	}

	&:focus:valid {
		~ .icon-ok {
			display: none;
		}

		~ .label-descr {
			color:#4D4F5B;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			top:2.5px;
		}
	}

	&:valid {
		~ .icon-ok {
			display: block;
		}

		~ .label-descr {
			color:#4D4F5B;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			top:2.5px;
		}
	}

	&:invalid:not(:focus):not(:placeholder-shown) {
		border-color:#FF002E;

		~ .icon-false {
			display: block;
		}

		~ .label-descr {
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			top:2.5px;
		}
	}

}

input[type=text]::-ms-clear {  
	display: none; width : 0; height: 0; 
}

input[type=text]::-ms-reveal {  
	display: none; width : 0; height: 0; 
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { 
	display: none; 
	-webkit-appearance:none;
	appearance:none;
}

/* checking */
.check {
	position: relative;
	display: flex;
	min-height:28px;
  	align-items: center;
  	font-size: 16px;
  	font-weight: 400;
  	line-height: 140%;
  	color:#111B2E;
  	text-align: left;
  	padding-left:40px;

  	&-group {
  		justify-content: flex-start;
  		gap:20px;
  	}

  	@media screen and (max-width:768px) {
  		font-size: 14px;
  	}
}

.check__input {
  	position: absolute;
  	width:1px;
  	height:1px;
  	overflow:hidden;
  	clip:rect(0 0 0 0);
}

.check__box {
  	position: absolute;
  	left:0px;
  	top:calc(50% - 14px);
  	width:28px;
  	height:28px;
  	border-radius:7px;
  	border:1px solid #CCCDDD;
}

.radio {
	.check__box {
		border-radius:100%;
	}

	.check__input:checked + .check__box {
		border-color:#1C84FF;
		background: #1C84FF;

		  &::before {
		  	position: absolute;
		  	content:"";
		  	width:12px;
		  	height:12px;
		  	border-radius:100%;
		  	background: #fff;
		  	top:calc(50% - 6px);
		  	left:calc(50% - 6px);
		  }
	}
}

.option {
	.check__input:checked + .check__box {
		border-color:#1C84FF;

		  &::before {
		  	position: absolute;
		  	content:"";
		  	@include sprite(29px,29px,"../img/sprite.svg#sprite--checkbox");
		  	top:0;
		  	left:0;
		  	margin-left: -1px;
		  	margin-top: -1px;
		  }
	}
}

/* select listing */
.select-listing {
	position: relative;
	width:368px;
	margin:0 auto;

	&-list {
		display: none;
		position: absolute;
		z-index: 4;
		width:100%;
		left:0;
		right:0;
		top:54px;
		border-radius: 6px;
		background: #FFF;
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
		padding:6px;

		&__item {
			display: block;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color:#101828;
			border-radius:4px;
			text-decoration: none;
			cursor: pointer;
			padding:12px 10px;

			&:hover {
				background: #F3F3F7;
				color:#101828;
			}
		}
	}

	.select-value {
		font-size: 17px;
		font-weight: 400;
	}

	&-wrapper {
		height:144px;
	}

	&-all {
		.select-btn {
			color:#101828;
			padding:22px 16px 9px;

			svg {
				min-width:14px;
				margin-top: -8px;
			}
		}

		.select-listing-list {
			top:62px;
		}
	}

	&.open {
		.select-btn {
			svg {
				transform:rotate(-180deg);
			}
		}

		.select-listing-list {
			display: block;
		}
	}
}

.select-btn {
	position: relative;
	width:100%;
	align-items: center;
	font-size: 17px;
	font-weight: 400;
	color:#656A7B;
	border-bottom: 1px solid #111B2E;
	height:56px;

	svg {
		fill:#111B2E;
	}

	&.select-choise {
		.select-value {
			color:#111B2E;
		}
	}
}

.select-title {
	position: absolute;
	top:8px;
	left:0;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color:#4D515B;
}

.select-listing {
	width:100%;

	&-list {
		top:59px;
	}

	.select-btn {
		width:100%;
		height:56px;
		padding-top: 20px;
	}
}

/* container */
main {
	padding-top: 80px;

	@media screen and (max-width:992px) {
		padding-top: 60px;
	}
}

.container {
	max-width:1240px;
	padding:0 20px;
	margin:0 auto;
}

.section {
	position: relative;
	padding:80px 0;

	&_dark {
		background: #171717 url("../img/decorate.svg") no-repeat; 
		background-position: bottom right;
		color:#fff;

		h2, .h2, .descr {
			color:#fff;
		}
	}

	&_white {
		background: #fff;
	}

	&_gray {
		background: #F2F4F6;
	}

	&_light {
		background: #F3F6FF url("../img/decorate-dark.svg") no-repeat;
		background-position: top left;
	}

	@media screen and (max-width:768px) {
		padding:50px 0;

		&_dark {
			background: #171717;
		}

		&_light {
			background: #F3F6FF;
		}
	}
}

.logo {
	img {
		margin: 0;
	}

	@media screen and (max-width:768px) {
		img {
			width:218px;
			height:32px;
		}
	}
}