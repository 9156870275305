/* footer */
.footer {
	background: #020A17;
	padding:80px 0;

	&_top {
		gap:40px 30px;
		align-items: center;
		padding-bottom: 30px;
	}

	&_bottom {
		text-align: center;
		padding-top: 30px;
	}

	&-info {
		flex:1;
		max-width:400px;
	}

	.logo {
		display: inline-block;
		margin-bottom: 30px;
	}

	&-nav {
		flex:1;
		gap:40px 30px;
	}

	&-heading {
		font-size: 14px;
		font-weight: 700;
		line-height: 150%;
		text-transform: uppercase;
		color:#8D8F9A;
		margin-bottom: 20px;
	}

	&__item {
		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}

	&-link {
		color:#fff;
		font-size: 17px;
		font-weight: 400;
		line-height: 150%;

		&:hover {
			color:#B6C4EF;
		}
	}

	.copy {
		font-size: 17px;
		font-weight: 400;
		line-height: 150%;
		color:rgba(255, 255, 255, 0.60);
	}

	.soc {
		justify-content: flex-start;
	}

	@media screen and (max-width:992px) {
		&_top {
			justify-content: center;
		}

		&-info, &-nav {
			min-width:100%;
			max-width:100%;
		}

		&-info {
			text-align: center;

			.soc {
				justify-content: center;
			}
		}
	}

	@media screen and (max-width:768px) {
		padding:50px 0;
	}
}