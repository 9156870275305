/* power */
.power {
	&-block {
		grid-template-columns:repeat(auto-fit,minmax(400px,1fr));
		grid-gap:40px;
	}

	&__item {
		border-radius: 10px;
		background: #FFF;
		padding:50px;

		.text {
			max-width:410px;

			p {
				color:#111B2E;
			}
		}

		.h3 {
			text-align: left;
			margin-bottom: 15px;
		}
	}

	&_blue {
		color:#fff;
		background: linear-gradient(76deg, #0957B2 0.7%, #1C84FF 100%);

		.h3 {
			color:#fff;
		}

		.text {
			p {
				color:#fff;
			}
		}
	}

	@media screen and (max-width:768px) {
		&-block {
			grid-template-columns:repeat(auto-fit,minmax(280px,1fr));
			grid-gap:20px;
		}

		&__item {
			padding:30px;

			.h3 {
				margin-bottom: 10px;
			}
		}
	}

}